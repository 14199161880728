import React from "react";

import DefaultLayout from "../../../../layouts/default";

const DocLReferenzen = () => (
  <DefaultLayout title="Referenzen – WiCare|Doc-L">
    <div className="white wicare">
      <div className="navContentSpacerHelper"></div>
      <header className="navWrapper">
        <nav className="mainNav">
          <div className="mainNavBackgroundHelper"></div>
          <div className="logo">
            <a href="/">
              <img
                src="/assets/images/logos/wigasoft-logo.svg"
                alt="WigaSoft Logo"
                width="175"
              />
            </a>
          </div>
          <div className="mainNav-opener"></div>
          <ul className="mainLinks">
            <li>
              <a href="/news/">News</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/heime/">Heime</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/spitaeler/">Spitäler</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/rehabilitation/">
                Rehabilitation
              </a>
            </li>
            <li>
              <a href="/portrait/">Über uns</a>
            </li>
            <li>
              <a href="/support/">Support</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/wigasoft-ag">
                <div className="linkedInIcon" />
              </a>
            </li>
          </ul>
        </nav>

        <nav className="subNav2">
          <div className="subNav2Product">
            <div className="productImage">
              <img
                src="/assets/images/logos/WCN-Doc.svg"
                alt="WiCare|Doc Logo"
                width="40"
              />
            </div>
            <div className="productText">
              <span className="line">WiCare|Doc-L</span>
              <span className="line">
                <span className="is-visible-desktop">
                  &nbsp;für Langzeit-Institutionen
                </span>
                &nbsp;nach BESA
              </span>
            </div>
          </div>
          <div className="subNav2-opener"></div>
          <ul className="subNav2Links">
            <li>
              <a href="/dokumentationsloesungen/wicare-doc-l/">Übersicht</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/wicare-doc-l/mobile/">Mobile</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/wicare-doc-l/technik/">
                Technik
              </a>
            </li>
            <li className="is-active">
              <a href="/dokumentationsloesungen/wicare-doc-l/referenzen/">
                Referenzen
              </a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/wicare-doc-l/downloads/">
                Downloads
              </a>
            </li>
          </ul>
        </nav>
      </header>

      <div className="menuBackground">
        <div className="wrapper">
          <section className="has-padding-top has-padding-bottom">
            <h2>WiCare|Doc in Langzeit-Betrieben</h2>
            <div className="referenzen">
              <article className="referenzen-item">
                <div className="referencesColorFlex">
                  <a href="/dokumentationsloesungen/wicare-doc-l/">
                    <div className="background is-blue"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                Jakobushaus – begleitung und pflege,
                  <br />
                  Thürnen (BL)
                </p>
                <p className="refKunden">Kunde seit 2023</p>
              </article>
              <article className="referenzen-item">
                <div className="referencesColorFlex">
                  <a href="/dokumentationsloesungen/wicare-doc-l/">
                    <div className="background is-blue"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                Schlossgarten Riggisberg, 
                  <br />
                  Riggisberg (BE)
                </p>
                <p className="refKunden">Kunde seit 2023</p>
              </article>
              <article className="referenzen-item">
                <div className="referencesColorFlex">
                  <a href="/dokumentationsloesungen/wicare-doc-l/">
                    <div className="background is-blue"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                  Kloster Claustra,
                  <br />
                  Ilanz (GR)
                </p>
                <p className="refKunden">Kunde seit 2019</p>
              </article>
              <article className="referenzen-item">
                <div className="referencesColorFlex">
                  <a href="/dokumentationsloesungen/wicare-doc-l/">
                    <div className="background is-blue"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                  Alterssiedlung Root,
                  <br />
                  in Root (LU)
                </p>
                <p className="refKunden">Kunde seit 2020</p>
              </article>
              <article className="referenzen-item">
                <div className="referencesColorFlex">
                  <a href="/dokumentationsloesungen/wicare-doc-l/">
                    <div className="background is-blue"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                  Pflegezentrum Sonnenberg,
                  <br />
                  Affoltern am Albis (ZH)
                </p>
                <p className="refKunden">Kunde seit 2017</p>
              </article>
              <article className="referenzen-item">
                <div className="referencesColorFlex">
                  <a href="/dokumentationsloesungen/wicare-doc-l/">
                    <div className="background is-blue"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                  Alterszentrum am Bachgraben,
                  <br />
                  Allschwil (BL)
                </p>
                <p className="refKunden">Kunde seit 2020</p>
              </article>
              <article className="referenzen-item">
                <div className="referencesColorFlex">
                  <a href="/dokumentationsloesungen/wicare-doc-l/">
                    <div className="background is-blue"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                  Alters- und Pflegeheim St. Martin,
                  <br />
                  Cazis (GR)
                </p>
                <p className="refKunden">Kunde seit 2017</p>
              </article>
              <article className="referenzen-item">
                <div className="referencesColorFlex">
                  <a href="/dokumentationsloesungen/wicare-doc-l/">
                    <div className="background is-blue"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                  Stiftung am Rhein,
                  <br />
                  Pflegezentrum Neugut,
                  <br />
                  Landquart (GR)
                </p>
                <p className="refKunden">Kunde seit 2017</p>
              </article>
              <article className="referenzen-item">
                <div className="referencesColorFlex">
                  <a href="/dokumentationsloesungen/wicare-doc-l/">
                    <div className="background is-blue"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                  Stiftung am Rhein,
                  <br />
                  Pflegezentrum Senesca,
                  <br />
                  Maienfeld (GR)
                </p>
                <p className="refKunden">Kunde seit 2017</p>
              </article>
              <article className="referenzen-item">
                <div className="referencesColorFlex">
                  <a href="/dokumentationsloesungen/wicare-doc-l/">
                    <div className="background is-blue"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                  Alters- und Pflegeheim Bachwiesen,
                  <br />
                  Ramsen (SG)
                </p>
                <p className="refKunden">Kunde seit 2010</p>
              </article>
              <article className="referenzen-item">
                <div className="referencesColorFlex">
                  <a href="/dokumentationsloesungen/wicare-doc-l/">
                    <div className="background is-blue"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                  Stiftung Regionales Alters- und Pflegeheim FRENKENBÜNDTEN,
                  <br />
                  Liestal (BL)
                </p>
                <p className="refKunden">Kunde seit 2020</p>
              </article>
              <article className="referenzen-item">
                <div className="referencesColorFlex">
                  <a href="/dokumentationsloesungen/wicare-doc-l/">
                    <div className="background is-blue"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                  Flury Stiftung,
                  <br />
                  Jenaz (GR)
                </p>
                <p className="refKunden">Kunde seit 2014</p>
              </article>
              <article className="referenzen-item">
                <div className="referencesColorFlex">
                  <a href="/dokumentationsloesungen/wicare-doc-l/">
                    <div className="background is-blue"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                  Flury Stiftung,
                  <br />
                  Klosters (GR)
                </p>
                <p className="refKunden">Kunde seit 2014</p>
              </article>
              <article className="referenzen-item">
                <div className="referencesColorFlex">
                  <a href="/dokumentationsloesungen/wicare-doc-l/">
                    <div className="background is-blue"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                  Flury Stiftung,
                  <br />
                  Schiers (GR)
                </p>
                <p className="refKunden">Kunde seit 2014</p>
              </article>
              <article className="referenzen-item">
                <div className="referencesColorFlex">
                  <a href="/dokumentationsloesungen/wicare-doc-l/">
                    <div className="background is-blue"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                  Gesundheitszentrum Savognin AG,
                  <br />
                  Savognin (GR)
                </p>
                <p className="refKunden">Kunde seit 2011</p>
              </article>
              <article className="referenzen-item">
                <div className="referencesColorFlex">
                  <a href="/dokumentationsloesungen/wicare-doc-l/">
                    <div className="background is-blue"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                  Wohn- und Pflegeheim Plaids,
                  <br />
                  Flims (GR)
                </p>
                <p className="refKunden">Kunde seit 2011</p>
              </article>
              <article className="referenzen-item">
                <div className="referencesColorFlex">
                  <a href="/dokumentationsloesungen/wicare-doc-l/">
                    <div className="background is-blue"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                  Alters- und Pflegeheim Verahus,
                  <br />
                  Balgach (SG)
                </p>
                <p className="refKunden">Kunde seit 2013</p>
              </article>
              <article className="referenzen-item">
                <div className="referencesColorFlex2">
                  <a href="/dokumentationsloesungen/wicare-doc-l/">
                    <div className="background is-blue"></div>
                  </a>
                  <a href="/dokumentationsloesungen/wicare-lep/">
                    <div className="background is-green"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                  Center da sandà Val Müstair,
                  <br />
                  Sta. Maria (GR)
                </p>
                <p className="refKunden">Kunde seit 2012</p>
              </article>
              <article className="referenzen-item">
                <div className="referencesColorFlex">
                  <a href="/dokumentationsloesungen/wicare-doc-l/">
                    <div className="background is-blue"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                  Alterszentrum Serata
                  <br />
                  der Stiftung Gott Hilft,
                  <br />
                  Zizers (GR)
                </p>
                <p className="refKunden">Kunde seit 2015</p>
              </article>
              <article className="referenzen-item">
                <div className="referencesColorFlex">
                  <a href="/dokumentationsloesungen/wicare-doc-l/">
                    <div className="background is-blue"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                  Alterszentrum am Schäflisberg,
                  <br />
                  St. Gallen (SG)
                </p>
                <p className="refKunden">Kunde seit 2015</p>
              </article>
              <article className="referenzen-item">
                <div className="referencesColorFlex">
                  <a href="/dokumentationsloesungen/wicare-doc-l/">
                    <div className="background is-blue"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                  Alterszentrum Bruggwiesen,
                  <br />
                  Effretikon (ZH)
                </p>
                <p className="refKunden">Kunde seit 2015</p>
              </article>
              <article className="referenzen-item">
                <div className="referencesColorFlex">
                  <a href="/dokumentationsloesungen/wicare-doc-l/">
                    <div className="background is-blue"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                  Casa sogn Guisep,
                  <br />
                  Cumpadials (GR)
                </p>
                <p className="refKunden">Kunde seit 2015</p>
              </article>
              <article className="referenzen-item">
                <div className="referencesColorFlex">
                  <a href="/dokumentationsloesungen/wicare-doc-l/">
                    <div className="background is-blue"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                  Heime der Stadt Aarau,
                  <br />
                  Golatti, Aarau (AG)
                </p>
                <p className="refKunden">Kunde seit 2014</p>
              </article>
              <article className="referenzen-item">
                <div className="referencesColorFlex">
                  <a href="/dokumentationsloesungen/wicare-doc-l/">
                    <div className="background is-blue"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                  Heime der Stadt Aarau,
                  <br />
                  Hérose, Aarau (AG)
                </p>
                <p className="refKunden">Kunde seit 2014</p>
              </article>
              <article className="referenzen-item">
                <div className="referencesColorFlex">
                  <a href="/dokumentationsloesungen/wicare-doc-l/">
                    <div className="background is-blue"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                  Alterssiedlung Sunneziel,
                  <br />
                  Meggen (LU)
                </p>
                <p className="refKunden">Kunde seit 2017</p>
              </article>
              <article className="referenzen-item">
                <div className="referencesColorFlex">
                  <a href="/dokumentationsloesungen/wicare-doc-l/">
                    <div className="background is-blue"></div>
                  </a>
                </div>
                <p className="headline5 medium smallStaticPadding-top">
                  Pflegeheim Heiligkreuz,
                  <br />
                  St. Gallen (SG)
                </p>
                <p className="refKunden">Kunde seit 2014</p>
              </article>
            </div>
          </section>
        </div>

            <section className="newsletterAnmeldung">
      <div className="wrapper nlPadding-top">
        <a className="btn grey" href="/newsletter">Newsletteranmeldung</a><br /><br /><br />
      </div>
    </section>

        <footer className="mainFooter">
          <div className="wrapper">
            <div className="breadcrumbs has-padding-small-top smallStaticPadding-bottom">
              <ul>
                <li>
                  <a href="/">Startseite</a>
                </li>
                <li>
                  <a href="/dokumentationsloesungen/wicare-doc-l/">
                    WiCare|Doc-L nach BESA
                  </a>
                </li>
                <li>
                  <a href="/dokumentationsloesungen/wicare-doc-l/referenzen/">
                    Referenzen
                  </a>
                </li>
              </ul>
            </div>
            <div className="footerNotAnotherFlexboxButAGrid linklist">
              <div className="flex-item1">
                <ul>
                  <li>
                    <a href="/dokumentationsloesungen/heime/">
                      <strong>Heime</strong>
                    </a>
                  </li>
                  <li>
                    <a href="/dokumentationsloesungen/spitaeler/">
                      <strong>Spitäler</strong>
                    </a>
                  </li>
                  <li>
                    <a href="/dokumentationsloesungen/rehabilitation/">
                      <strong>Rehabilitation</strong>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item2">
                <ul>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-l/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-L
                      BESA
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-l/index_rai">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-L
                      RAI-NH
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-b/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-B
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-h/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-H
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-r/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-R
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item3">
                <ul>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-tacs/">
                      WiCare|now tacs<sup>&#174;</sup>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-lep/">
                      WiCare|now LEP<sup>&#174;</sup>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-le/">
                      WiCare|now LE
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item4">
                <ul>
                  <li>
                    <a href="/portrait/">
                      <strong>Über uns</strong>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/lehrlinge/">Lernende</a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/partner/">Partner</a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/mitgliedschaften/">Mitgliedschaften</a>
                  </li>
                  <li>
                    <a href="/portrait/kontakt/">Kontakt und Anfahrt</a>
                  </li>
                </ul>
              </div>
              <div className="flex-item5">
                <ul>
                  <li>
                    <a href="/support/">
                      <strong>Support</strong>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row copyright">
            <div className="wrapper">
              <div className="column">
                © 2025 WigaSoft. Alle Rechte vorbehalten.&nbsp;
              </div>
              <div className="column has-padding-left">
                <ul>
                  <li>
                    <a href="/datenschutz/">Datenschutzerklärung</a>
                  </li>

              <li>
                <a href="/impressum/">Impressum</a>
              </li>
                </ul>
              </div>
              <div className="column align-right">
                <a href="/portrait/kontakt/" className="anfahrt">
                  <img
                    id="location-pointer"
                    src="/assets/images/icons/icon-anfahrt.svg"
                  />
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </DefaultLayout>
);

export default DocLReferenzen;
